<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <!-- 출력 -->
        <c-btn
          label="LBL0000312"
          icon="print"
          @btnClicked="print" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param="param"
          :task="task"
          :height="height"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskOnePage',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: 'onepage',
      task: {
        insertUrl: '',
        deleteUrl: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if ((this.editable && !this.disabled) || this.btnEditable) {
        numHeight = numHeight - 30
      }
      return numHeight + 'px';
    },
    tabItems() {
      let oneComponent = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        oneComponent = () => import(`${'@/pages/ram/hazop/hazopOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        oneComponent = () => import(`${'@/pages/ram/kras/krasOnePageDetail.vue'}`)
      } 
      let items = [
        { key: '1', name: 'onepage', icon: 'plagiarism', label: 'LBL0001233', component: oneComponent }, // 한눈에 보기
        { key: '2', name: 'education', icon: 'edit_calendar', label: 'LBL0001234', component: () => import(`${'@/pages/ram/riskEducation.vue'}`) }, // 관련 교육
        { key: '5', name: 'status', icon: 'equalizer', label: 'LBL0001235', component: () => import(`${'@/pages/ram/riskStatus.vue'}`) }, // 통계
      ]
      if (this.param.vendorFlag) {
        items.splice(1, 1)
      }
      return items;
    },
    printUrl() {
      let url = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        url = selectConfig.ram.hazop.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        url = selectConfig.ram.kras.print.url;
      } 
      return url;
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.task.insertUrl = transactionConfig.ram.assessPlan.task.insert.url;
      this.task.deleteUrl = transactionConfig.ram.assessPlan.task.delete.url;
      // code setting
      // list setting
    },
    print() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let excelName = this.assessmentName ? this.assessmentName : this.$comm.getToday();
          let fileOrgNm = (this.param.ramTechniqueCd === 'RT00000001' ? 'HAZOP_' : 'KRAS_') + excelName + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>